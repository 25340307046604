import { useRouter } from 'next/router'
import { useUser } from '@/providers/userProvider'
import { Routes } from '@/core/routes'
import { useEffect } from 'react'

export const withNotProtectedRoute = (
  WrappedComponent: React.ComponentType
): ((props) => JSX.Element) => {
  const ComponentWithPopup = (props) => {
    const { isLoggedIn } = useUser()
    const router = useRouter()

    useEffect(() => {
      if (isLoggedIn) {
        typeof window !== 'undefined' && router.push(Routes.home)
      }
    }, [isLoggedIn])

    if (isLoggedIn) {
      return null
    }

    return <WrappedComponent key={WrappedComponent.displayName} {...props} />
  }
  ComponentWithPopup.displayName = `withProtectedRoute(${WrappedComponent.displayName})`

  return ComponentWithPopup
}

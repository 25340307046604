import { Translate } from 'next-translate'
import { Input } from '@/components/molecules/form/form'
import { baseValidator, emailValidator } from '@/core/validationUtils'

export const makeLoginInputFields = (t: Translate): Input[] => [
  {
    title: t('LoginPage.Email.Form.email'),
    subTitle: t('LoginPage.Email.Form.emailHint'),
    name: 'email',
    placeholder: t('LoginPage.Email.Form.email'),
    rules: [
      {
        errorMessage: t('LoginPage.Email.Form.emptyEmailErrorPlaceholder'),
        isValid: baseValidator
      },
      {
        errorMessage: t('LoginPage.Email.Form.notValidEmailErrorPlaceholder'),
        isValid: emailValidator
      }
    ]
  },
  {
    title: t('LoginPage.Email.Form.password'),
    name: 'password',
    placeholder: t('LoginPage.Email.Form.password'),
    valueType: 'password',
    rules: [
      {
        errorMessage: t('LoginPage.Email.Form.emptyPasswordErrorPlaceholder'),
        isValid: baseValidator
      }
    ]
  }
]

import { FC, useEffect } from 'react'
import LoginForm from '@/components/organisms/login/loginForm'
import { Flex } from '@/components/atoms/Grid'
import { Routes } from '@/core/routes'
import { Page } from '@/network/graphql.g'
import { isLoggedInBySession } from '@/providers/userProvider'
import { withNotProtectedRoute } from '@/HOC/withNotProtectedRoute'
import { withBaseServerSideProps } from '@/HOC/withBaseServerSideProps'
import { requestThrow401 } from '@/utils/graphQl'
import { useMhub } from '@/providers/mhubProvider'
import { getMainData } from '@/utils/mainDataHelper'
import { useSpeedCurve } from '@/core/speedCurve/useSpeedCurve'

const Login: FC = () => {
  const { pushLoginPage } = useMhub()
  useEffect(() => {
    pushLoginPage()
  }, [])

  useSpeedCurve({
    label: 'Login'
  })

  return (
    <Flex
      justifyContent="center"
      width="100%"
      pt={{ mobile: 30, tablet: 40, desktop: 60 }}
      pb={100}
    >
      <LoginForm />
    </Flex>
  )
}

export const getServerSideProps = withBaseServerSideProps(
  async ({
    session,
    sdk,
    withMainNavigation,
    withStore
  }): Promise<GetServerSideProps<Rest>> => {
    if (isLoggedInBySession(session)) {
      return {
        redirect: {
          permanent: false,
          destination: Routes.home
        }
      }
    }

    const { response } = await requestThrow401({
      method: sdk.getSeoTags,
      variables: {
        page: Page.Login,
        withMainNavigation,
        withStore
      }
    })

    return {
      props: {
        mainData: getMainData(response),
        seoTags: response?.seoTags
      }
    }
  }
)

export default withNotProtectedRoute(Login)

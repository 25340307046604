import { FC, useContext, useEffect, useState } from 'react'
import { Box, Flex } from '@/components/atoms/Grid'
import styled, { useTheme } from 'styled-components'
import { Capitalized, Tiny2 } from '@/components/atoms/typography'
import useTranslation from 'next-translate/useTranslation'
import FormInput from '@/components/molecules/form/formInput'
import FormElement from '@/components/molecules/form/formElement'
import Link from '@/components/atoms/link'
import { Routes } from '@/core/routes'
import { makeLoginInputFields } from '@/components/organisms/login/loginFormData'
import {
  getValidationResult,
  initData,
  Input,
  InputValue,
  isFormValid,
  onInputChange
} from '@/components/molecules/form/form'
import { BigBlackButton } from '@/components/atoms/button'
import Trans from 'next-translate/Trans'
import { useAuth } from '@/providers/authProvider'
import { useAsset } from '@/providers/assetsProvider'
import LoginSocialButtons from './loginSocialButtons'
import { StoreContext } from '@/providers/storeProvider'

const LoginForm: FC = () => {
  const { t } = useTranslation('common')
  const theme = useTheme()
  const fieldsData: Input[] = makeLoginInputFields(t)
  const [showValidation, setShowValidation] = useState<boolean>(false)
  const [loginData, setLoginData] = useState<Record<string, InputValue>>({})
  const { loginByCredentials } = useAuth()
  const { isShutDownCountry } = useAsset()

  useEffect(() => {
    initData(fieldsData, setLoginData)
  }, [])

  const handleCredentialsLogin = (e) => {
    e.preventDefault()

    setShowValidation(true)
    const formIsValid = isFormValid(loginData)

    if (formIsValid) {
      loginByCredentials(loginData.email.value, loginData.password.value)
    }
  }

  const { isAliatic } = useContext(StoreContext)

  return (
    <>
      <Flex
        flexDirection="column"
        justifyContent="stretch"
        alignItems="center"
        width={{ mobile: '100%', tablet: 500, desktop: 500 }}
        p={{ mobile: 12, tablet: 0, desktop: 0 }}
      >
        <Flex
          justifyContent="center"
          alignItems="center"
          width="100%"
          mt={40}
          mb={20}
          mx={33}
        >
          <StyledCapitalized px={15}>
            {t('LoginPage.Email.title')}
          </StyledCapitalized>
        </Flex>

        <StyledForm onSubmit={handleCredentialsLogin}>
          <Box width="100%">
            {fieldsData.map(
              ({ name, subTitle, placeholder, rules, type, valueType }) => {
                const { isValid, errorMessage } = getValidationResult(
                  loginData[name],
                  rules
                )
                return (
                  <Box key={name}>
                    <FormElement
                      showValidation={showValidation}
                      isValid={isValid}
                    >
                      <FormInput
                        name={name}
                        value={loginData[name]?.value}
                        width="100%"
                        formInputType={type}
                        type={valueType}
                        placeholder={placeholder}
                        isError={showValidation && !isValid}
                        onChange={(name, value) => {
                          onInputChange(name, value, rules, setLoginData)
                        }}
                        errorPlaceholder={errorMessage}
                      />
                    </FormElement>
                    {subTitle && (
                      <Tiny2
                        m="0 0 20px 0"
                        color={theme.colors.inputPlaceholder}
                      >
                        {subTitle}
                      </Tiny2>
                    )}
                  </Box>
                )
              }
            )}
            <Tiny2 m="0 0 30px 0">
              <Link
                href={Routes.forgottenPassword}
                textDecoration={'underline'}
              >
                {t('LoginPage.Email.Form.forgottenPassword')}
              </Link>
            </Tiny2>
          </Box>
          <BigBlackButton
            type="submit"
            mb={15}
            width="100%"
            backgroundColor={theme.colors.onBackground}
            onClick={handleCredentialsLogin}
            title={t('LoginPage.Email.action')}
            data-cy="loginButton"
          />
        </StyledForm>

        {!isShutDownCountry ? (
          <Capitalized display="inline-block" m={0}>
            <Trans
              i18nKey="common:LoginPage.Email.register"
              components={[
                <span />,
                <Link href={Routes.registration} textDecoration={'underline'} />
              ]}
            />
          </Capitalized>
        ) : null}

        {!isAliatic && <LoginSocialButtons />}
      </Flex>
    </>
  )
}

export default LoginForm

const StyledCapitalized = styled(Capitalized)`
  text-transform: uppercase;
  margin: 0;
  background-color: ${(props) => props.theme.colors.background};
`

const StyledForm = styled.form`
  width: 100%;
  position: relative;
`
